.project-section{
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin-bottom: 128px;
    opacity: 0; /* Start hidden */
    animation: fadeInFromBottom 0.4s ease-in-out forwards;
    animation-delay: 0.4s; /* Optional: Adds a slight delay for a staggered effect */
}

.project {
    margin: 0;
    height: 100%;
    display: flex;
    flex-flow: column;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.project:disabled {
  cursor: not-allowed;
}
  
.project h2 {

    font-size: 36px;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
}

.project p{
    width: 100%;
}

.intro {
    margin: 128px auto;
    text-align: center;
    align-content: center;
    margin-bottom: 100px;
    width: 100%;
    animation: fadeInFromTop 0.3s ease-in-out forwards;
}

.intro h1 {
    font-family: 'Lexend', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 36px;
    font-weight: 300;
    color: #201E1D;
    width: 800px;
    margin: 0 auto;
    margin-bottom: 24px;
    
  }
  
  
  .intro p {
    font-size: 12px;
    font-weight: 300;
    color: #201E1D;
    font-family: 'Lexend', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  /* Project styling */
.project-menu {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: -80px;
  }

  .project-menu img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    
  }

 .image-right{
    margin-right: -80px;
 }
  
  .project-cover {
    width: 100%;
    height: 400px;
    overflow: hidden;
    background-position: center;
    background-size: contain;
    border: 2px solid #201E1D;
  }

  .project-cover.hovered {
    border-color: #3C008A;
  }
  
  
  /* Project Info - overlay on the image */

  .project-info {
    bottom: 15px;
    margin-left:-80px;
    background-color: #F4F1EA;
    color: #201E1D;
    padding: 24px 32px 32px 32px;
    width: 320px;
    border: 2px solid #201E1D;
    text-align: left;
  }

  .project-info.hovered {
    border-color: #3C008A;
  }
  
  .project-info h3 {
    font-size: 32px;
    margin: 0;
  }
  
  .project-info p {
    font-size: 16px;
    color: #4D4846;
  }

  .work-section{
    text-align: left;
  }



/* Style the animated popup div */
.project-popup {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 64PX);
    background: #fff;
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  /* When the project div is visible, animate it to full opacity and size */
.project-container .project-popup {
    opacity: 1;
    transform: scale(1);
  }

 .homepage{
  width: 100%;
  max-width: 1184px;
  margin: 0 auto;
 }

 .homepage h2{
  animation: fadeInFromTop 0.3s ease-in-out forwards;
 }


 /* Fade-in from top */
@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade-in from bottom */
@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .intro {
    margin: 104px auto;
    width: 100%;
    max-width: 100vw;
    color: blue;
}

.intro h1 {
    padding: 20px;
    width: auto;
    line-height: 130%;
    }

  .intro p{
    padding: 0 20px;
    width: auto;
    line-height: 180%;
  }

  .project-menu {
    flex-direction: column;
    padding: 0px;
  }

  .project-menu img{
    border-left: 0px;
    border-right: 0px;
    
  }

  .project-cover {
    border-width: 2px 0; /* Only top and bottom borders */
    height: 100vw;
    width: 100vw;
    overflow: visible;
  
  }

  .project-info{
    margin-left: 0px;
    border: none;
  }
  
}
