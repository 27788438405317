.navbar {
  position: sticky; /* Makes the navbar stick to its position */
  top: 0;           /* Positions it at the top */
  z-index: 1000;    /* Ensures it stays on top of other elements */
  display: flex;
  align-items: center;
  padding: 0;

  color: #201E1D;
  height: 64px;
  transition: transform 0.3s ease-in-out;


}

.navbar.hidden {
  transform: translateY(-63px);
}

.logo {
  display: flex;
  width: 64px;
  line-height: 64px;
  text-align: center;
  border-color: #201E1D;
  border-width: 0 2px 2px 0;
  border-style: solid;
}

.logo a{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin: 0;
}

.spacer{
  border-color: #201E1D;
  border-width: 0 0 2px 0;
  border-style: solid;
  width: 100%;
  height: 100%;
}

.menu {

  display: flex; /* Display tabs in a row */
  list-style-type: none; /* Remove default list styles */
  padding: 0;
  line-height: 64px;
  margin: 0;
  font-family: 'Lexend', sans-serif;
  font-weight: 600;
}

.menu li {
  margin: 0;
  padding: 0;
}

/* Styling for each tab */
.menu li a {
  display: block;
  padding: 0 16px;
  text-decoration: none;
  color: var(--text-primary); /* Default text color */
  border-bottom: 2px solid #201E1D; /* Remove the bottom border for each tab */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect for each tab */
.menu li a:hover {
  color: #201E1D;
  background-color: #E5D8D1; /* Change background color on hover */
  border-bottom: 2px solid #201E1D; /* Remove the bottom border for each tab */

}

/* Active tab styling */
.menu li a.active {
  color: #5800CC; /* Text color for the active tab */
  border-bottom: 2px dashed #5800CC;
  transition: beckground-color 0.3s ease, border-bottom 0.3s ease, color 0.3s ease;
}



.custom-button {
  font-family: 'Lexend', sans-serif;
  font-weight: 600;
  line-height: 64px;
  font-size: 16px;
  border-color: #201E1D;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-bottom-left-radius: 2px;
  background-color: #F4F1EA; /* Default background color */
  color: #66605D;
  cursor: pointer;
  padding: 0 16px;
  text-decoration: none;
}

.custom-button:hover {
  color: #201E1D;
  border-style: solid;
}

.custom-button:active {
  color: #201E1D;
}

.custom-button.active{
  text-decoration: none;
  color: #201E1D;
  border-color: #201E1D;
  border-width: 0 2px;
  border-style: solid;
  border-bottom-color: #F4F1EA ;
  
}


/* Tooltip container */
.icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  display: block;
  padding: 0 16px;
  text-decoration: none;
  color: var(--text-primary); /* Default text color */
  border-bottom: 2px solid #201E1D; /* Remove the bottom border for each tab */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.icon-container:hover{
  background-color: #E5D8D1;
  color: #201E1D;/* Default text color */
}

/* Tooltip text */
.tooltip {
  position: absolute;
  bottom: -36px; /* Adjust to position below the icon */
  left: -50%;
  transform: translateX(-148px);
  background-color: var(--background-primary);
  color: var(--text-primary);
  padding: 12px 8px;
  border: 2px dashed var(--button-background-active);
  font-size: 14px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 1000;
  width: 200px;
  text-align: center;
  line-height: 20px;
  font-family: 'Lexend', sans-serif;
  font-weight: 600;
}

/* Tooltip fade-out */
.tooltip.hidden {
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 768px) {

  .navbar {
    background-color:#F4F1EA;
  }
}
