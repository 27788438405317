:root {
  /*Buttons*/
  --button-background-active: #5800CC;
  --button-background-hover: #3C008A;
  --button-background-pressed: #3C008A;
  --button-background-focus: #3C008A;
  --button-disabled-bg: #F4F1EA;
  --button-disabled-text: #99908B;
  --button-disabled-border: #99908B;
  --button-label-tertiary-active: #5800CC;

  /*Backgrounds*/
  --background-primary:#F4F1EA;

  /*Texts*/
  --text-primary:#201E1D;
  --text-secondary: #66605D;
  --text-tertiary:#99908B;
  --text-quaternary:#F4F1EA;


  /*Borders*/
  --border-primary:#201E1D;
  --border-secondary: #5800CC;


}



h2{
  font-family: 'Lexend', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    font-weight: 600;
    color: #66605D;
    text-align: left;
    width: auto;
}

.main-content {
  margin: 0 auto;
  height: 100% auto;
  background-color: #F4F1EA;
  text-align: center;
  width: 100%;
  align-items: center;
  display: flex;
}

.work-section {
  display: flex;
  flex-direction: column;
  gap: 120px;
  margin: 0 auto;
}

.work-section h2 {
  margin-bottom: 1.5rem;
  text-align: left;
  text-transform: uppercase;
}

.wrapper{
  max-width: 100vw;
  height: auto;
  background-color: #F4F1EA;
}

/* Mobile Styles */
@media (max-width: 768px) {
  h2 {
    font-size: 22px;
    text-align: left;
    margin-left: 20px;
  }

  .main-content {
    padding: 0px;

  }

  .work-section {
    gap: 60px;
    padding: 8px;
  }

  .work-section h2 {
    font-size: 12px;
    text-align: left;
  }

  .wrapper {
    padding: 0px;
  }
}
