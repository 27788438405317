
 a {
     text-decoration: none;
 }
 
 .button-large {
     background-color: var(--button-background-active);
     line-height: 40px;
     font-family: 'Mukta', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-weight: 600;
     font-size: 16px;
     color: #F4F1EA;
     border: none;
     padding: 8px 16px;
     cursor: pointer;
 }
 
 .button-large a {
     text-decoration: none;
 }
 
 .button-large:active {
     background-color: var(--button-background-active);
 }
 
 .button-large:hover {
     background-color: var(--button-background-hover);
 }
 
 .button-large:focus {
     border: 0 0 0 6px var(--button-background-focus);
     border-radius: 0px;
 }
 
 .button-large:pressed {
     border: 0 0 0 6px var(--button-background-focus);
     border-radius: 0px;
 }

 .button-large.disabled {
    background-color: #99908B; /* Makes the button appear faded */
    color: #F4F1EA;
    cursor: not-allowed; /* Changes the cursor to indicate it is not clickable */
  }
  
  button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
 
 /*Index list item*/
 
 .index-list-item {
     line-height: 40px;
     font-size: 16px;
     color: #F4F1EA;
     border: none;
     padding: 0;
     cursor: pointer;
     display: flex;
     flex-direction: row;
     height: 80px;
     width: 100%;
     background-color: #F4F1EA;
 }
 
 .index-list-item a {
     text-decoration: none;
 }
 
 .index-list-item:focus {
     border-radius: 0px;
 }
 
 .index-list-item:pressed {
     border-radius: 0px;
 }
 
 .index-list-item-content {
     height: 100%;
     width: 100%;
     margin: 0 auto;
     display: flex;
     flex-direction: row;
     color: #201E1D;
 }
 
 .index-list-item-number {
     height: 100%;
     min-width: 80px;
     margin: auto auto;
     align-items: center;
     font-family: 'Lexend', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-weight: 600;
     font-size: 38px;
     line-height: 80px;
     background-color: #F4F1EA;
 }
 
 .index-list-item-number:hover {
     background-color: var(--button-background-active);
 }
 
 .index-list-item-label {
     height: 100%;
     width: 100%;
     margin: auto auto;
     align-items: center;
     font-family: 'Lexend', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-weight: 600;
     font-size: 38px;
     line-height: 80px;
     text-align: left;
     padding: 0 24px;
 }
 
 .index-list-item-icon {
     height: 100%;
     min-width: 80px;
     margin: auto auto;
     align-items: center;
     font-family: 'Lexend', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-weight: 600;
     font-size: 32px;
     line-height: 80px;
 }
 
 /*Floating navigation*/
 
 .floating-navigation {
     position: fixed;
     bottom: 20px;
     right: 20px;
     display: flex;
     flex-direction: row;
     gap: 0px;
     z-index: 10;
 }
 
 .floating-navigation button {
     font-family: 'Mukta', sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-weight: 600;
     font-size: 16px;
     line-height: 40px;
     background-color: #F4F1EA;
     color: #5800CC;
     border: 2px solid #5800CC;
     padding: 8px 16px;
     cursor: pointer;
     transition: background-color 0.3s, color 0.3s;
     display: flex;
     align-items: center;
     width: auto;
 }
 
 .floating-navigation button:hover {
     border-color: var(--button-background-hover);
     color: var(--button-background-hover);
 }
 
 .floating-navigation button:disabled {
     background-color: var(--button-disabled-bg);
     color: var(--button-disabled-text);
     border-color: var(--button-disabled-border);
     cursor: not-allowed;
     pointer-events: none;
 }
 
 .floating-navigation button:disabled:hover {
     background-color: var(--button-disabled-bg); /* Prevent hover effects */
     color: var(--button-disabled-text);
 }
 

 .floating-nav-btn {
    font-family: 'Mukta', sans-serif;
    font-weight: 600;
    font-size: 16px;
    background-color: #f4f1ea;
    color: #5800cc;
    border: 2px solid #5800cc;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .floating-nav-btn:hover:not(.disabled) {
    border: 2px solid #3C008A;
    color: var(--button-background-hover);
  }
  
  .floating-nav-btn.disabled {
    background-color: #999;
    color: #fff;
    cursor: not-allowed;
  }
  
  .floating-nav-btn:disabled {
    pointer-events: none;
  }
  

  @media (max-width: 768px) {

 .index-list-item {
    line-height: 24px;
    font-size: 16px;
    color: #F4F1EA;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 100%;
    background-color: #F4F1EA;
}

.index-list-item a {
    text-decoration: none;
}

.index-list-item:focus {
    border-radius: 0px;
}

.index-list-item:pressed {
    border-radius: 0px;
}

.index-list-item-content {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    color: #201E1D;
}

.index-list-item-number {
    min-width: 40px;
    margin: auto auto;
    font-size: 18px;
    line-height: 40px;
}

.index-list-item-number:hover {
    background-color: var(--button-background-active);
}

.index-list-item-label {
    height: 100%;
    width: 100%;
    font-size: 18px;
    line-height: 40px;
    padding: 0 16px;
}

.index-list-item-icon {

    min-width: 40px;
    font-size: 16px;
    line-height: 40px;
}


  }