.project-body{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  height: auto;
  margin: 0 auto;
  padding: 0;
  align-items: center;
  overflow-x: hidden;
}

.project-header{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  height: calc(100vh - 64px);
  margin: 0 auto;
  padding: 0;
}

.project-header h2{
  font-family: "Lexend Zetta", sans-serif;
  font-size: 120px;
  font-weight: 600;
  text-align: center;
 height: auto;
  margin: 0 auto;
  padding: 64px 0;
  color: #201E1D;
}

.project-header-image{
  height: 100%;
  width: auto;
  background-image: url( '../assets/stryk-project-cover.webp');
  background-repeat: no-repeat;
  background-size: cover;
  border-width: 2px 0;
  border-style: solid;
  border-color: #201E1D;
  image-rendering: auto;

  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
}

.project-index{
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  display: flex;
}

.index-wrapper{

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1084px;
  margin: 0 auto;
  gap: 32px;
  height: auto;
  align-items: center;
}

.project-section{
  display: flex;
  align-items: center;
}

.section-cover {
  width: 100%; 
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #E5D8D1;
}

.text-slide-section{
  width: 100%;
  max-width: 1084px;
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.section-limit{
  margin: 0 auto;
  max-width: 1084px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
}



.section-number {
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  font-family: "Lexend Zetta", sans-serif;
  font-size: 80px;
  font-weight: 600;
  text-align: center;
  background-color: #33302E;
  color: #F4F1EA;
  line-height: 200px;
  position: relative;
  overflow: hidden; /* Ensures the animation stays within bounds */
}

.section-number::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid #5800CC; /* Purple outline */
  box-sizing: border-box;
  z-index: 1;
  opacity: 0;
  animation: none;
}

.section-number.animate::after {
  opacity: 1;
  animation: draw-and-reverse 4s ease-in-out forwards;
}

@keyframes draw-and-reverse {
  0% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%); /* Nothing shows */
  }
  12% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%); /* Border on the top */
  }
  25% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%); /* Border on top and right */
  }
  37% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); /* Border on top, right, and bottom */
  }
  50% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); /* Full border */
  }
  66% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%); /* Border on right, bottom, and left */
  }
  75% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); /* Border on bottom and left */
  }
  87% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 100%); /* Border on left only */
  }
  100% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%); /* Nothing shows */
  }
}


.section-title{
  width: 100%;
  height: 200px;
  min-height: 200px;
  font-family: "Lexend Zetta", sans-serif;
  font-size: 80px;
  font-weight: 600;
  text-align: center;
  height: auto;
  color: #201E1D;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 64px;
  justify-content: center;
}

.section-decoration{
  
  width: 100px;
  border-bottom: 2px dashed #5800CC;
  margin-left: 8px;
}

.slide-title{
  font-family: "Lexend Zetta", sans-serif;
  font-size: 38px;
  font-weight: 600;
  text-align: left;
  color: #66605D;
  text-align: right;

}

@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateX(-20px); /* Start slightly to the left */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* End in the final position */
  }
}


.slide-text {
  padding-left: 64px;
  flex-direction: column;
  gap: 16px; /* Adjust spacing between paragraphs */
}

.slide-text p{
  font-family: "Lexend", sans-serif;
  font-size: 38px;
  font-weight: 400;
  text-align: left;
  color: #201E1D;
  border-left: 2px dashed #5800CC;
  padding-left: 24px;
  opacity: 0; /* Initially hidden */
  transform: translateX(-20px); /* Initial position */
  transition: none; /* Disable transition for the reset */
}

.slide-text.in-view p {
  animation: fadeInSlide 0.4s ease-in-out forwards;
}

.slide-text.in-view p:nth-child(1) {
  animation-delay: 0s;
}

.slide-text.in-view p:nth-child(2) {
  animation-delay: 0.2s; /* Staggered delay */
}

.slide-text.in-view p:nth-child(3) {
  animation-delay: 0.4s;
}


.image-section{
  width: 70%;
  height: 100vh;
  margin: 0 auto;
  align-items:flex-start;
  text-align: center;
  display: flex;
  flex-direction: column;
}



.image-slide-title{
  width: auto;
  text-align: left;
  font-family: "Lexend", sans-serif;
  font-size: 38px;
  font-weight: 600;
  color: #66605D;
  height: auto;
  padding-top:80px ;
}


@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Start slightly to the left */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End in the final position */
  }
}


.image-slide-image-group{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Start position */
  transition: none; /* Disable transition for reset state */
}

.video-slide-title{
  width: 100%;
  text-align: left;
  font-family: "Lexend", sans-serif;
  font-size: 38px;
  font-weight: 600;
  color: #66605D;
  height: auto;
  padding-top:80px ;
}

.video-slide-group{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Start position */
  transition: none; /* Disable transition for reset state */
}

.video-slide-group.in-view {
  animation: fadeInSlideUp 0.4s ease-in-out forwards;
}

.video-slide-image {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 40px 0;
  max-height: calc(100vh - 200px);
  width: 70%;
}

.video-slide-image img {
  height: 100%; /* Matches the height of the video */
  width: 100%;
}

.video-slide-video {
  flex: 1; /* Ensures the video and image take equal space */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
}

.video-slide-video video {
  height: auto; /* Let video size adjust naturally */
  max-height: calc(100vh - 200px); /* Keep it within viewport height */
  width: auto;
}



.image-slide-image-group.in-view {
  animation: fadeInSlideUp 0.4s ease-in-out forwards;
}

.image-slide-image{
  width: 100%;
  align-items: center;
  display: flex;
}

.image-slide-image img{
  width: 100%;
  height: auto;
}

.image-slide-caption{
  width: 100%;
  height: auto;
  text-align: center;
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #66605D;
  padding-top: 24px;
}



/* Fullscreen overlay */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(244, 241, 234, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: background-color 0.4s ease-in-out;
}

/* Fullscreen content (image) */
.fullscreen-content img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

/* Close icon */
.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #5800CC;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
  transition: transform 0.3s ease;
}

.close-icon:hover {
  color: var(button-background-hover);
}




.launch-screen-animation{
  height: 350px;
  background-color: #167CD9;
  width: 160px;
  display: flex;
  align-items: center;
}

.animation-section{
  width: 70%;
  height: 100vh;
  margin: 0 auto;
  align-items:flex-start;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: aqua;
}

.column-slide-section{
  width: 70%;
  height: 100vh;
  margin: 0 auto;
  align-items:center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.column-slide-columns{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 24px;
}

.column-slide-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 16px;
}

.column-title{
  text-align: left;
  font-family: "Lexend", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #66605D;
}

.column-text{
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #201E1D;
  text-align: left;
}

@media (max-width: 768px) {

  .project-header h2{
    font-family: "Lexend Zetta", sans-serif;
    font-size: 54px;
    font-weight: 600;
    text-align: center;
    height: auto;
    margin: 0 auto;
    padding: 64px 0;
    color: #201E1D;
  }

  .project-index{
    width: auto;
    height: auto;
    margin:80px 32px;
    align-items: center;
    display: flex;
  }

  .section-cover {
    width: 100vw; 
    height: 100vh;
  }

  .section-limit{
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    max-width: auto;
    width: 100%;
    justify-content: center;
    text-align: center;
    gap: 40px
  }

  .section-number{
    max-width: 100px;
    min-width: 100px;
    width: 100px;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    font-size: 40px;
    line-height: 100px;
  }
  .section-title{
    height: auto;
    min-height: auto;
    text-align: center;
    font-size: 30px;
    padding-left: 0px;
  }
  
  .image-section{
    width: 100%;
    max-width: 100vw;
    height: auto;
    margin: 80px 0px;
    text-align: left;
    align-items: center;
    gap: 40px;
  }

  .text-slide-section{
    max-width: 100vw;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    margin: 80px auto;
  }
  
  .slide-text {
    padding-left: 0px;
    margin: 0 32px;
    gap: 48px; /* Adjust spacing between paragraphs */
  }

  .slide-text p{
    font-size: 24px;
  }

  .image-slide-title{
    text-align: left;
    font-family: "Lexend", sans-serif;
    font-size: 24px;
    height: auto;
    padding: 0 32px;
  }

  .image-slide-image-group{
    width: 70%;

  }

  .video-slide-group{

    flex-direction: column;
    max-width: 100vw;
  }

  .slide-title{
    text-align: center;
  }

  .video-slide-title{
    font-size: 24px;
    height: auto;
    padding: 0 32px !important; 
  }

  .video-slide-image {
    
    
  }

  .video-slide-image img {
    max-width: 100vw;
    
  }
  
}