body {
  margin: 0;
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F1EA;
  overflow-y: scroll;

}

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lexend+Zetta:wght@100..900&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lexend-zetta-<uniquifier> {
  font-family: "Lexend Zetta", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

