 /* Fade-in from left */
 @keyframes fadeInFromLeft {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Fade-in from right */
  @keyframes fadeInFromRight {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

/* Fade-in from top */
@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  
.logo-about{
    animation: fadeInFromTop 0.4s ease-in-out forwards;
    padding-left: 40px;
}

.about{
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin: 64px auto;
    text-align: left;
}


.work-section{
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-top: 48px;

}

.work-title{
    text-align: right;
    min-width: 100px;
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color:var(--text-tertiary);
    animation: fadeInFromLeft 0.4s ease-in-out forwards;
    
}

.work-experiences{
    display: flex;
    flex-wrap: wrap; /* Allows the items to wrap into multiple rows */
    gap: 48px; /* Adjust the space between items */
    width: 100%;
    animation: fadeInFromRight 0.4s ease-in-out forwards;
}

.experience-block{
    flex: 1 0 calc(50% - 48px); /* Take up 50% of the row minus the gap */
    box-sizing: border-box; /* Ensure padding and gap are considered in the width calculation */
    max-width: calc(50% - 48px);
}
.experience-dates{
    font-family: 'Lexend', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-secondary);
    padding-top: 8px;
}

.experience-title{
    font-family: 'Lexend', sans-serif;
    font-size: 26px;
    font-weight: 600;
    color: var(--text-primary);
    padding-top: 4px;
}

.experience-company{
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-secondary);
    padding-top: 2px;
    line-height: 18px;
}

.experience-description{
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-primary);
    padding-top: 16px;
    line-height: 26px;
}

@media (max-width: 768px) {
  .logo-about{
    width: 70%;
    padding-left: 32px;
  }

  .about{
    margin: 32px auto;
  }

  .work-section{
    flex-direction: column;
    padding: 32px;
}

.work-title{
  text-align: left;
  border-left: 2px dashed var(--border-secondary);
  padding-left: 8px;

}
.work-experiences{
  flex-wrap: column; /* Allows the items to wrap into multiple rows */
}

.experience-block{
  flex: none;
  width: 100%;  
  max-width: 100%;
}

.experience-description{ 
  padding-top: 16px;
}
}


